
  
  .glow {
    font-size: 2rem;
    color: #fff;
    text-align: center;
    animation: glow 1s ease-in-out infinite alternate;
  }
  
  @keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #6d39ff, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #6d39ff;
    }
    
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #6d39ff, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #6d39ff, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
    }
  }